import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImageModule } from "../common/Image_Module"
import './assets/styles/_index.scss';
import NoImg from "../../images/no-image.png"

const HolidaySimilarProperties = (props) => {

    const HOLIDAY_DETAILS = gql`
        query GetAllHoliday ($price: String!) {
            properties(
                limit: 3
                where: {publish: "true", department: "short_lets" price_gt: $price}
            ) {
                id
                area
                display_address
                slug
                search_type
                publish
                price
                images
                imagetransforms
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
                description
            }
        }
    `;

    const holidayId = props?.holidayData?.id;
    const holidayPrice = props?.holidayData?.price;
    
    const { data: dataHoliday } = useQuery(HOLIDAY_DETAILS, {
        variables: { price: holidayPrice.toString() }
    });

    if (!dataHoliday) {
        return null;
    }

    const allHolidayHomes = dataHoliday?.properties?.filter(holidayValue => holidayId !== holidayValue?.id);

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    return (
        <>
            {
                allHolidayHomes.length > 0 &&
                <section className="similar-properties-wrapper">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="similar-heading">Similar Properties</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Slider className="similar-properties-slider" {...settings}>
                                    {
                                        allHolidayHomes.map((item, key) => {

                                            let processedImages = JSON.stringify({});
                                            if (item?.imagetransforms?.images_Transforms) {
                                                processedImages = item.imagetransforms.images_Transforms;
                                            }

                                            let propertyUrl = (item.search_type === "sales" ? "/holiday-homes-for-sale/" : "/holiday-homes-for-rent/")+item.slug+"-"+item.id+"/";

                                            return (
                                                <div className="similar-properties-slide">
                                                    <div className="similar-properties-img-zoom">
                                                        {
                                                            item?.images.length > 0 ?
                                                            <Link to={propertyUrl}>
                                                                <ImageModule
                                                                    imagename={"property.images.featuredProperties"}
                                                                    ImageSrc={item?.images[0]}
                                                                    ggfx_results={item?.ggfx_results}
                                                                    altText={item?.display_address}
                                                                    strapi_id={item?.id}
                                                                />
                                                            </Link>
                                                            :
                                                            <Link to={propertyUrl}>
                                                                <img src={NoImg} alt={item?.display_address + "- White & Co Real Estate"} />
                                                            </Link>
                                                        }
                                                    </div>
                                                    <div className="similar-properties-text-wrapper">
                                                        {
                                                            item?.description &&
                                                            <p className="display-address display-desc">{item?.description}</p>
                                                        }
                                                        {
                                                            item?.price &&
                                                            <div className="price-details">{item?.price.toLocaleString()} {"AED"}</div>
                                                        }
                                                        {
                                                            item?.display_address &&
                                                            <p className="display-address">{item?.display_address}</p>
                                                        }
                                                        {
                                                            item?.area &&
                                                            // <div className="location-details d-flex align-items-center">
                                                            <div className="location-details d-flex align-items-center">
                                                                <i className="icon icon-map-pin"></i>
                                                                <span>{item?.area}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
        </>
    )
}

export default HolidaySimilarProperties